import { generateVisitorId } from '@cof/generate-visitor-id';
import { StorageKey, SessionStorageKey, getLocalStorageValue, getSessionStorageValue, removeLocalStorageValue, setLocalStorageValue, setSessionStorageValue, DwiteStorageType, getLocalStorageFromDwite, getSessionStorageFromDwite } from '@/utils/storage-utilities';
export const generateAndStoreUserData = (firstName, lastName, email, phoneNumber, dealerId, visitorId, leadId) => {
    visitorId ?? (visitorId = generateVisitorId(firstName, lastName, email, phoneNumber));
    setLocalStorageValue(StorageKey.VisitorId, [dealerId, visitorId]);
    if (leadId) {
        setSRSessionDataForDealer(dealerId, StorageKey.LeadId, leadId);
    }
    return visitorId;
};
export const setSRSessionDataForDealer = (dealerId, type, value) => {
    const existingData = sessionStorage.getItem(dealerId)
        ? JSON.parse(sessionStorage.getItem(dealerId))
        : null;
    sessionStorage.setItem(dealerId, JSON.stringify({ ...existingData, [type]: value }));
};
export const getSRSessionDataForDealer = (dealerId, type) => {
    const storedData = sessionStorage.getItem(dealerId);
    if (!storedData) {
        return null;
    }
    const parsedData = JSON.parse(storedData);
    return parsedData[type] !== undefined ? parsedData[type] : null;
};
export const getStoredVisitorId = (dealerId) => {
    const visitorId = getLocalStorageValue(StorageKey.VisitorId, false);
    return visitorId?.split(',')[0] === dealerId ? visitorId?.split(',')[1] : undefined;
};
export const clearStoredUserData = (dealerId) => {
    removeLocalStorageValue(StorageKey.VisitorId);
};
// Save finance filters to session storage to allow UC and Search persisting filters
export const saveFinanceFiltersToSession = (financeFilters) => {
    // Validate all values
    const allowedLoanTerms = [48, 60, 72, 84];
    if (financeFilters.selectedLoanTerm && !allowedLoanTerms.includes(financeFilters.selectedLoanTerm))
        return;
    if (financeFilters.downPayment && financeFilters.downPayment < 0)
        financeFilters.downPayment = 0;
    if (financeFilters.tradeInRemainingBalance && financeFilters.tradeInRemainingBalance < 0)
        financeFilters.tradeInRemainingBalance = 0;
    if (financeFilters.tradeInVehicleValue && financeFilters.tradeInVehicleValue < 0)
        financeFilters.tradeInVehicleValue = 0;
    // Keep existing tradein values if not provided
    const existingFinanceFilters = getFinanceFiltersFromSession();
    financeFilters.tradeInRemainingBalance =
        financeFilters.tradeInRemainingBalance || existingFinanceFilters.tradeInRemainingBalance;
    financeFilters.tradeInVehicleValue = financeFilters.tradeInVehicleValue || existingFinanceFilters.tradeInVehicleValue;
    setSessionStorageValue(SessionStorageKey.FinanceFilters, JSON.stringify(financeFilters));
};
export const getFinanceFiltersFromSession = () => {
    const financeFilters = getSessionStorageValue(SessionStorageKey.FinanceFilters, false);
    return financeFilters ? JSON.parse(financeFilters) : {};
};
// Extract the idpData that LN stores and return the data if it isn't expired
export const getLeadsNavIdpData = async () => {
    try {
        const idpData = await JSON.parse(await getLocalStorageFromDwite(StorageKey.LeadsNavIDP, DwiteStorageType.GLOBAL));
        return idpData && idpData.expiration > Date.now() / 1000 ? idpData : undefined;
    }
    catch (e) {
        return undefined;
    }
};
// Extract the pqId that LN saves to local from the stored leadsNavIDP
export const getStoredLeadsNavPqId = async () => {
    try {
        const idpData = await getLeadsNavIdpData();
        if (idpData) {
            return idpData[StorageKey.LeadsNavPQId] ? idpData[StorageKey.LeadsNavPQId] : undefined;
        }
        return undefined;
    }
    catch (e) {
        return undefined;
    }
};
// Extract the lender list that LN saves to local storage
export const getStoredLeadsNavLenders = async (dealerId, leadsNavClientToken) => {
    try {
        const storageKey = `${leadsNavClientToken}:dealerLenders-${dealerId}`;
        const data = JSON.parse(await getLocalStorageFromDwite(storageKey, DwiteStorageType.GLOBAL));
        // Create and return lenderId string list
        return data?.value ? data.value.map((lender) => lender?.lenderId) : undefined;
    }
    catch (e) {
        return undefined;
    }
};
// Extract the leadId that LN saves to session storage
export const getStoredLeadsNavLeadId = async () => {
    try {
        const encryptedLeadInfo = await getSessionStorageFromDwite(StorageKey.LeadsNavLeadId, DwiteStorageType.DOMAIN);
        const leadInfo = JSON.parse(atob(encryptedLeadInfo));
        return leadInfo?.leadId ?? undefined;
    }
    catch (e) {
        return undefined;
    }
};
