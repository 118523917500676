import { constructUrl } from '@/services/service-config';
import { initTealium, setExternalVar } from '@/services/tagging-service';
import { getClientCorrelationId } from '@/utils/correlation-id';
const baseURL = '/protected/958910/auto/container/';
const getTenantId = () => new URLSearchParams(window.location.search).get('tid');
const buildSearchParamString = (inputParams) => {
    const params = new URLSearchParams(inputParams);
    const tid = getTenantId();
    !tid || params.set('tenantId', tid); // if (tid) then add it to the params
    const paramString = params.toString();
    return paramString.length ? `?${paramString}` : '';
};
export const serviceGetContainer = async (lid, vin) => {
    if (process.env.NODE_ENV !== 'production')
        if (new URLSearchParams(location.search).has('mock'))
            (await import('@/services/mock/landing-page-bff-mock')).default(); // Create mock server in dev env
    const res = await fetch(`${constructUrl(baseURL, `link/${lid}/metadata`)}${buildSearchParamString(vin ? { vin } : undefined)}`, {
        headers: {
            Accept: 'application/json;v=1',
            'Client-Correlation-Id': getClientCorrelationId()
        }
    });
    if (res.ok) {
        const returnVal = await res.json();
        return returnVal;
    }
    if (res.status === 404)
        throw Error('404');
    throw Error(`Error occurred while querying ${lid}`);
};
export const getVehicleData = async (vin, dealerId, isDealerGroup, additionalDealerIds) => {
    if (process.env.NODE_ENV !== 'production')
        if (new URLSearchParams(location.search).has('mock'))
            (await import('@/services/mock/landing-page-bff-mock')).default(); // Create mock server in dev env
    const dealerIdForVehicleLookup = isDealerGroup
        ? { dealerId: JSON.stringify(additionalDealerIds?.concat(dealerId)) }
        : { dealerId: JSON.stringify([dealerId]) };
    const res = await fetch(`${constructUrl(baseURL, `vin/${vin}/details`)}${buildSearchParamString(dealerIdForVehicleLookup)}`, {
        headers: {
            Accept: 'application/json;v=1',
            'Content-Type': 'application/json',
            'Client-Correlation-Id': getClientCorrelationId()
        }
    });
    if (res.ok) {
        const responseVehicle = (await res.json());
        initTealium(responseVehicle);
        setExternalVar('vin', responseVehicle.vin);
        return responseVehicle;
    }
    throw Error(String(res.status));
};
